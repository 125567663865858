<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">结算单管理</a>
          <!--财务-->
        </span>
      </div>
      <div class="framePage-body">
        <el-tabs v-model="activeName" :before-leave="beforeTabLeave" @tab-click="tabsChange">
          <el-tab-pane label="收款结算单" name="first">
            <collectionStatementList :name="activeName" ref="first" @get="clearParams" />
          </el-tab-pane>
          <el-tab-pane label="付款结算单" name="second">
            <paymentStatementList :name="activeName" ref="second" />
          </el-tab-pane>
          <el-tab-pane label="代理结算单" name="three">
            <agentStatementList :name="activeName" ref="three" />
          </el-tab-pane>
          <el-tab-pane label="代理结算单(普惠)" name="four">
            <agencyStatementList :name="activeName" ref="four" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
import collectionStatementList from "@/views/financialSettlement/statementManagementFinance/collectionStatementList.vue";
import paymentStatementList from "@/views/financialSettlement/statementManagementFinance/paymentStatementList.vue";
import agentStatementList from "@/views/financialSettlement/statementManagementFinance/agentStatementList.vue";
import agencyStatementList from "@/views/financialSettlement/statementManagementFinance/agencyStatementList.vue";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "ClassReview",
  components: {
    collectionStatementList,
    paymentStatementList,
    agentStatementList,
    agencyStatementList
  },
  mixins: [List],
  data() {
    return {
      activeName: ""
    };
  },
  created() {
    this.activeName = this.$route.query.active || "first";
    // this.name = this.$route.query.name || "first";
  },
  mounted() {
    if (this.$route.query.active == "second") {
      this.$refs["second"].getData(
        -1,
        "/run/project/auditPassListPage",
        "second"
      );
    } else if (this.$route.query.active == "three") {
      this.$refs["three"].getData(
        -1,
        "/run/project/auditRejectListPage",
        "three"
      );
    } else {
      this.$refs["first"].getData(
        -1,
        "/run/project/waitAuditListPage",
        "first"
      );
    }
  },
  methods: {
    beforeTabLeave(newName) {
      this.$router.replace({
        path: this.$route.path,
        query: { active: newName }
      });
    },
    tabsChange(e) {
      this.activeName = e.name;
      this.name = e.name;
      if (e.name == "second") {
        this.$refs.second.getData(1, "/run/project/auditPassListPage", e.name);
      }  else {
        this.$refs.first.getData(1, "/biz/new/bill/pageList", e.name);
      }
    //   this.$refs[e.name].ruleForm.Trainingtype = '';
    //   this.$refs[e.name].params = {};
    //   this.$refs[e.name].projectName = '';
    //   this.$refs[e.name].areaId = '';
    //   this.$refs[e.name].projectUser = '';
    //   this.$refs[e.name].projectUserPhone = '';
    //   this.$refs[e.name].projectCode = '';
    //   this.$refs[e.name].compId = '';
      this.$refs[e.name].getData(-1);
    }
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: function(route) {
      if (route.query.refresh) {
        this.$refs[route.query.active].getData(-1);
      }
    }
  }
  
};
</script>
<style lang="less">
.qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 400px;
    height: 400px;
    background-color: #999; //设置白色背景色
  }
}
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
</style>
