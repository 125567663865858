<template>
  <div>
    <div
      class="operationControl flexdc operationControlWidth"
      style="align-items: flex-start"
    >
      <div class="searchbox" style="margin-bottom: 5px">
        <div title="结算单编号" class="searchboxItem ci-full">
          <span class="itemLabel">结算单编号:</span>
          <el-input
            v-model="retrievalDatas.billNo"
            type="text"
            size="small"
            placeholder="请输入结算单编号"
            clearable
          />
        </div>
        <div title="机构名称" class="searchboxItem ci-full">
          <span class="itemLabel">机构名称:</span>
          <el-select
            size="small"
            v-model="retrievalDatas.compId"
            remote
            :remote-method="getCompanyList"
            filterable
            clearable
            placeholder="请至少输入两个字搜索"
          >
            <el-option
              v-for="item in CompanyList"
              :key="item.compId"
              :label="item.compName"
              :value="item.compId"
            ></el-option>
          </el-select>
        </div>
        <div title="结算单状态" class="searchboxItem ci-full">
          <span class="itemLabel">结算单状态:</span>
          <el-select
            size="small"
            v-model="retrievalDatas.auditType"
            placeholder="请选择结算单状态"
            clearable
          >
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div title="所属年度" class="searchboxItem ci-full">
          <span class="itemLabel">所属年度:</span>
          <el-select
            size="small"
            v-model="retrievalDatas.affiliationYear"
            placeholder="请选择所属年度"
            clearable
          >
            <el-option
              v-for="item in retrievalYearSelectData"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="searchbox" style="margin: 10px 0 5px">
        <div title="是否开票" class="searchboxItem ci-full">
          <span class="itemLabel">是否开票:</span>
          <el-select
            size="small"
            v-model="retrievalDatas.invoice"
            placeholder="请选择是否开票"
            clearable
          >
            <el-option label="未开票" :value="0"></el-option>
            <el-option label="已开票" :value="1"></el-option>
            <el-option label="部分开票" :value="2"></el-option>
          </el-select>
        </div>
        <div title="付款机构" class="searchboxItem ci-full">
          <span class="itemLabel">付款机构:</span>
          <el-input
            v-model="retrievalDatas.payerName"
            type="text"
            size="small"
            placeholder="请输入付款机构"
            clearable
          />
        </div>
        <div title="是否记账" class="searchboxItem ci-full">
          <span class="itemLabel">是否记账:</span>
          <el-select
            size="small"
            v-model="retrievalDatas.chargeUp"
            placeholder="请选择是否记账"
            clearable
          >
            <el-option label="已记账" :value="true"></el-option>
            <el-option label="未记账" :value="false"></el-option>
          </el-select>
        </div>
        <div title="现负责人" class="searchboxItem ci-full">
          <span class="itemLabel">现负责人:</span>
          <el-select
            size="small"
            v-model="retrievalDatas.currentPrincipal"
            placeholder="请选择现负责人"
            clearable
          >
            <el-option
              v-for="item in currentPrincipaltData"
              :key="item.saleId"
              :label="item.saleName"
              :value="item.saleId"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="searchbox" style="margin: 10px 0 5px">
        <div title="票据类型" class="searchboxItem ci-full">
          <span class="itemLabel">票据类型:</span>
          <el-select
            size="small"
            v-model="retrievalDatas.invoiceType"
            placeholder="请选择票据种类"
            clearable
          >
            <el-option
              v-for="item in invoice_type"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div title="有无照片" class="searchboxItem ci-full">
          <span class="itemLabel">有无照片:</span>
          <el-select
            size="small"
            v-model="retrievalDatas.withImg"
            placeholder="请选择有无照片"
            clearable
          >
            <el-option label="有" :value="true"></el-option>
            <el-option label="无" :value="false"></el-option>
          </el-select>
        </div>
        <div title="有无原单" class="searchboxItem ci-full">
          <span class="itemLabel">有无原单:</span>
          <el-select
            size="small"
            v-model="retrievalDatas.withBill"
            placeholder="请选择有无原单"
            clearable
          >
            <el-option label="有" :value="true"></el-option>
            <el-option label="无" :value="false"></el-option>
          </el-select>
        </div>
        <div title="代付款单位" class="searchboxItem ci-full">
          <span class="itemLabel">代付款单位:</span>
          <el-input
              v-model="retrievalDatas.replacePayerName"
              type="text"
              size="small"
              placeholder="请输入代付款单位"
              clearable
            />
        </div>
      </div>
      <div class="searchbox" style="margin: 10px 0 5px">
        <div title="收款日期" class="searchboxItem ci-full">
          <span class="itemLabel">收款日期:</span>
          <el-date-picker
            clearable
            size="small"
            v-model="retrievalDatas.startTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </div>
        <div title="创建日期" class="searchboxItem ci-full">
          <span class="itemLabel">创建日期:</span>
          <el-date-picker
            clearable
            size="small"
            v-model="retrievalDatas.createTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </div>
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          round
          @click="getData()"
          >查询</el-button
        >
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          round
          @click="getExport()"
          >导出</el-button
        >
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          round
          @click="openYearLoading"
          >设置所属年度</el-button
        >
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          round
          @click="openScenePersonInChargeLoading"
          >设置现负责人</el-button
        >
        <el-button
            class="bgc-bv"
            style="margin: 0 10px"
            round
            @click="doBatchChargeUp"
        >批量记账</el-button>
      </div>
    </div>
    <div style="margin: 15px 0.675rem 5px">
      <span>合计收款金额：{{ payerAmountSum }} 元</span>
      <span style="margin: 0 30px"
        >合计结算人数：{{ settlementUserCountSum }} 人</span
      >
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
          @selection-change="handleSelectionChange"
          :row-key="(row) => row.billId"
        >
          <el-table-column
            type="selection"
            width="55"
            :reserve-selection="true"
          >
          </el-table-column>
          <el-table-column
            label="序号"
            align="center"
            type="index"
            fixed
            :index="indexMethod"
          />
          <el-table-column
            label="结算单编号"
            align="left"
            show-overflow-tooltip
            prop="billNo"
            fixed
            min-width="180px"
          >
            <template slot-scope="scope">
              <div
                :style="{
                  color:
                    scope.row.auditType == '90' || scope.row.auditType == '99'
                      ? 'red'
                      : '',
                }"
              >
                {{ scope.row.billNo }}
              </div>
            </template>
          </el-table-column>

          <el-table-column
            label="机构名称"
            align="left"
            show-overflow-tooltip
            prop="compName"
            min-width="250px"
          >
            <template slot-scope="scope">
              <div
                :style="{
                  color:
                    scope.row.auditType == '90' || scope.row.auditType == '99'
                      ? 'red'
                      : '',
                }"
              >
                {{ scope.row.compName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="付款机构名称"
            align="left"
            show-overflow-tooltip
            prop="payerName"
            min-width="200px"
          >
            <template slot-scope="scope">
              <div
                :style="{
                  color:
                    scope.row.auditType == '90' || scope.row.auditType == '99'
                      ? 'red'
                      : '',
                }"
              >
                {{ scope.row.payerName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="代付款单位"
            align="left"
            show-overflow-tooltip
            prop="replacePayerName"
            min-width="200px"
          >
            <template slot-scope="scope">
              <div
                :style="{
                  color:
                    scope.row.auditType == '90' || scope.row.auditType == '99'
                      ? 'red'
                      : '',
                }"
              >
                {{ scope.row.replacePayerName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="收款金额"
            align="right"
            show-overflow-tooltip
            prop="payerAmount"
          >
            <template slot-scope="scope">
              <div
                :style="{
                  color:
                    scope.row.auditType == '90' || scope.row.auditType == '99'
                      ? 'red'
                      : '',
                }"
              >
                {{ scope.row.payerAmount }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="平台结算金额"
            align="right"
            show-overflow-tooltip
            prop="settlementAmount"
            width="120"
          >
            <template slot-scope="scope">
              <div
                :style="{
                  color:
                    scope.row.auditType == '90' || scope.row.auditType == '99'
                      ? 'red'
                      : '',
                }"
              >
                {{ scope.row.settlementAmount }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="到账金额"
            align="right"
            show-overflow-tooltip
            prop="arrivalAmount"
          >
            <template slot-scope="scope">
              <div
                :style="{
                  color:
                    scope.row.auditType == '90' || scope.row.auditType == '99'
                      ? 'red'
                      : '',
                }"
              >
                {{ scope.row.arrivalAmount }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="收款日期"
            align="right"
            show-overflow-tooltip
            prop="payeeDate"
            max-width="150px"
            min-width="150px"
          >
            <template slot-scope="scope">
              <div
                :style="{
                  color:
                    scope.row.auditType == '90' || scope.row.auditType == '99'
                      ? 'red'
                      : '',
                }"
              >
                {{ scope.row.payeeDate | momentDate }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="到账经办日期"
            align="left"
            show-overflow-tooltip
            prop="arrivalTime"
            width="160"
          >
            <template slot-scope="scope">
              <div
                :style="{
                  color:
                    scope.row.auditType == '90' || scope.row.auditType == '99'
                      ? 'red'
                      : '',
                }"
              >
                {{ scope.row.arrivalTime }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="是否打折结算单"
            align="left"
            show-overflow-tooltip
            prop="discounted"
            width="140"
          >
            <template slot-scope="scope">
              <div
                :style="{
                  color:
                    scope.row.auditType == '90' || scope.row.auditType == '99'
                      ? 'red'
                      : '',
                }"
              >
                {{ scope.row.discounted == true ? "是" : "否" }}
              </div>
            </template>
          </el-table-column>

          <el-table-column
            label="结算人数"
            align="right"
            show-overflow-tooltip
            prop="settlementUserCount"
            max-width="100px"
          >
            <template slot-scope="scope">
              <div
                :style="{
                  color:
                    scope.row.auditType == '90' || scope.row.auditType == '99'
                      ? 'red'
                      : '',
                }"
              >
                {{ scope.row.settlementUserCount }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="有无照片"
            align="right"
            show-overflow-tooltip
            prop="withImg"
            max-width="100px"
          >
            <template slot-scope="scope">
              <div
                :style="{
                  color:
                    scope.row.auditType == '90' || scope.row.auditType == '99'
                      ? 'red'
                      : '',
                }"
              >
                {{ scope.row.withImg }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="上传照片时间"
            align="right"
            show-overflow-tooltip
            width="150"
          >
            <template slot-scope="scope">
              <div
                :style="{
                  color:
                    scope.row.auditType == '90' || scope.row.auditType == '99'
                      ? 'red'
                      : '',
                }"
              >
                {{ scope.row.withImgDate | momentDate }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="有无原单"
            align="right"
            show-overflow-tooltip
            prop="withBill"
            max-width="100px"
          >
            <template slot-scope="scope">
              <div
                :style="{
                  color:
                    scope.row.auditType == '90' || scope.row.auditType == '99'
                      ? 'red'
                      : '',
                }"
              >
                {{ scope.row.withBill }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="上传原单时间"
            align="right"
            show-overflow-tooltip
            width="150"
          >
            <template slot-scope="scope">
              <div
                :style="{
                  color:
                    scope.row.auditType == '90' || scope.row.auditType == '99'
                      ? 'red'
                      : '',
                }"
              >
                {{ scope.row.withBillDate | momentDate }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="结算单收回日期"
            align="right"
            show-overflow-tooltip
            width="200"
          >
            <template slot-scope="scope">
              <div
                :style="{
                  color:
                    scope.row.auditType == '90' || scope.row.auditType == '99'
                      ? 'red'
                      : '',
                }"
              >
                {{ scope.row.billRecycleDate | momentDate }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="是否开票" align="center" max-width="150">
            <div
              slot-scope="scope"
              class="table-switch"
              :style="{
                color:
                  scope.row.auditType == '90' || scope.row.auditType == '99'
                    ? 'red'
                    : '',
              }"
            >
              <span v-if="scope.row.isInvoice == 0">未开票</span>
              <span v-if="scope.row.isInvoice == 1">已开票</span>
              <span v-if="scope.row.isInvoice == 2">部分开票</span>
            </div>
          </el-table-column>
          <el-table-column
            label="开票金额"
            align="right"
            show-overflow-tooltip
            prop="invoiceFee"
            max-width="60px"
          >
            <template slot-scope="scope">
              <div
                :style="{
                  color:
                    scope.row.auditType == '90' || scope.row.auditType == '99'
                      ? 'red'
                      : '',
                }"
              >
                {{ scope.row.invoiceFee }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="开票日期"
            align="right"
            show-overflow-tooltip
            prop="invoiceDate"
            width="150px"
          >
            <template slot-scope="scope">
              <div
                :style="{
                  color:
                    scope.row.auditType == '90' || scope.row.auditType == '99'
                      ? 'red'
                      : '',
                }"
              >
                {{ scope.row.invoiceDate }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="是否记账"
            align="center"
            show-overflow-tooltip
            prop="chargeUp"
            max-width="300px"
            min-width="150px"
          >
            <div slot-scope="scope" class="table-switch">
              <el-switch
                :width="20"
                v-model="scope.row.chargeUp"
                :active-value="true"
                :inactive-value="false"
                active-color="#13ce66"
                @change="
                  (val) => {
                    handleCharge(
                      scope.row.chargeUp,
                      scope.row.billId,
                      scope.$index
                    );
                  }
                "
              ></el-switch>
              <span>{{ scope.row.chargeUp ? "已记账" : "未记账" }}</span>
            </div>
          </el-table-column>
          <el-table-column
            label="结算单状态"
            align="center"
            show-overflow-tooltip
            prop="audit_type"
            max-width="300px"
            min-width="150px"
          >
            <template slot-scope="scope">
              <span
                :style="{
                  color:
                    scope.row.auditType == '90' || scope.row.auditType == '99'
                      ? 'red'
                      : '',
                }"
              >
                {{ $setDictionary("FD_AUDIT_TYPE", scope.row.auditType) }}
                <span v-show="scope.row.billSource == '30'" style="color: red"
                  >({{ "直接生成" }})</span
                >
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="票据类型"
            align="center"
            show-overflow-tooltip
            prop="invoiceType"
            max-width="300px"
            min-width="150px"
          >
            <template slot-scope="scope">
              <span
                :style="{
                  color:
                    scope.row.auditType == '90' || scope.row.auditType == '99'
                      ? 'red'
                      : '',
                }"
              >
                {{ $setDictionary("INVOICE_TYPE", scope.row.invoiceType) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="创建日期"
            align="right"
            show-overflow-tooltip
            prop="createTime"
            max-width="150px"
            min-width="150px"
          >
            <template slot-scope="scope">
              <div
                :style="{
                  color:
                    scope.row.auditType == '90' || scope.row.auditType == '99'
                      ? 'red'
                      : '',
                }"
              >
                {{ scope.row.createTime | moment }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="所属年度"
            align="right"
            show-overflow-tooltip
            prop="affiliationYear"
          >
            <template slot-scope="scope">
              {{ scope.row.affiliationYear || '未设置' }}
            </template>
          </el-table-column>
          <el-table-column
            label="现负责人"
            align="right"
            show-overflow-tooltip
            prop="currentPrincipalName"
          >
            <template slot-scope="scope">
              {{ scope.row.currentPrincipalName || '未设置' }}
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="400"
            fixed="right"
          >
            <template slot-scope="scope">
              <div style="text-align: left">
                <el-button
                  type="text"
                  size="mini"
                  style="padding: 0 5px"
                  :disabled="scope.row.auditType == '10'"
                  @click="goWatch(scope.row.billId)"
                  >预览</el-button
                >

                <el-button
                  type="text"
                  size="mini"
                  style="padding: 0 5px"
                  :disabled="
                    scope.row.auditType != '20' &&
                    scope.row.auditType != '30' &&
                    scope.row.auditType != '40'
                  "
                  @click="
                    goAudit(scope.row.billId, scope.row.contractId, 'first')
                  "
                  >{{
                    scope.row.auditType == "30" || scope.row.auditType == "40"
                      ? "再次复核"
                      : "复核"
                  }}</el-button
                >
                <el-button
                  type="text"
                  size="mini"
                  style="padding: 0 5px"
                  :disabled="!scope.row.enableFeedBack"
                  @click="goConfirmBill(scope.row.billId, '10')"
                  >已收回结算单</el-button
                >
                <el-button
                  type="text"
                  size="mini"
                  style="padding: 0 5px"
                  :disabled="!scope.row.enableConfirmReceipt"
                  @click="goconfirmMoney(scope.row.billId, '20')"
                  >确认收款</el-button
                >
                <el-button
                  type="text"
                  size="mini"
                  style="padding: 0 5px"
                  :disabled="!scope.row.enablePartIntoAccount"
                  @click="partialCollection(scope.row.billId, '20')"
                  >部分收款</el-button
                >
                <el-button
                  type="text"
                  size="mini"
                  style="padding: 0 5px"
                  @click="lookuploadfile(scope.row.billId)"
                  >查看附件</el-button
                >
                <el-button
                  type="text"
                  size="mini"
                  style="padding: 0 5px"
                  :disabled="scope.row.auditType == '90'"
                  @click="
                    bkpList(
                      scope.row.billId,
                      scope.row.invoiceType,
                      scope.row.invoiceDate
                    )
                  "
                  >开票</el-button
                >
                <el-button
                  type="text"
                  size="mini"
                  style="padding: 0 5px"
                  :disabled="
                    scope.row.auditType != '60' &&
                    scope.row.auditType != '65' &&
                    scope.row.auditType != '70' &&
                    scope.row.auditType != '80'
                  "
                  @click="backOff(scope.row.billId)"
                  >回退</el-button
                >
                <el-button
                  type="text"
                  size="mini"
                  style="padding: 0 5px"
                  @click="listDownloadClassList(scope.row)"
                  >下载班级列表
                </el-button>
                <el-button
                  type="text"
                  size="mini"
                  style="padding: 0 5px"
                  @click="listDownloadStatement(scope.row)"
                  >下载结算单
                </el-button>
                <el-button
                  type="text"
                  size="mini"
                  style="padding: 0 5px"
                  @click="openYearLoading(scope.row)"
                  >设置所属年度</el-button
                >
                <el-button
                  type="text"
                  size="mini"
                  style="padding: 0 5px"
                  @click="openScenePersonInChargeLoading(scope.row)"
                  >设置现负责人</el-button
                >
              </div>
            </template>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <!-- 预览 -->
    <el-dialog
      title="结算单"
      :visible.sync="dialogCert"
      width="50%"
      top="2%"
      center
      :before-close="closeDownloadCert"
    >
      <div style="height: 580px" class="ovy-a">
        <div id="pdf-cert" style="height: 580px"></div>
        <div class="el-div"></div>
        <h3 style="padding-bottom: 20px">流转记录</h3>
        <div>
          <el-table
            ref="multipleTable"
            :data="ListData"
            size="small"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="tableHeader"
            stripe
          >
            <el-table-column
              label="审核时间"
              align="center"
              show-overflow-tooltip
              prop="createTime"
            />
            <el-table-column
              label="审核人"
              align="center"
              show-overflow-tooltip
              prop="fullname"
            />
            <el-table-column
              label="审核状态"
              align="center"
              show-overflow-tooltip
              prop="auditType"
            >
              <template slot-scope="scope">
                <span>
                  {{
                    $setDictionary(
                      "FD_NEW_AUDIT_TYPE_RECORD",
                      scope.row.auditType
                    )
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              label="备注"
              align="center"
              show-overflow-tooltip
              prop="remark"
            >
              <template slot-scope="scope">
                {{ scope.row.remark || "--" }}
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              align="center"
              width="150px"
              fixed="right"
            >
              <div slot-scope="scope">
                <el-button
                  type="text"
                  size="mini"
                  :disabled="!scope.row.hasFile"
                  style="padding: 0 5px"
                  @click="see(scope.row)"
                  >预览附件</el-button
                >
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
    <!-- 已收回结算单/确认收回 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogFormVisible"
      @close="cancle('ruleForm')"
    >
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules">
        <el-form-item :label="labelTitle + ':'" label-width="130px" prop="date">
          <el-date-picker
            v-model="ruleForm.date"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancle('ruleForm')">取 消</el-button>
        <el-button
          type="primary"
          @click="dialogFormVisibleSure('ruleForm')"
          class="bgc-bv"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 部分收款 -->
    <el-dialog
      title="部分到账"
      :visible.sync="dialogPartialCcollectionVisible"
      @close="canclePartialCcollection('ruleForm')"
    >
      <el-table
        ref="multipleTable"
        :data="ProjectData"
        size="small"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="tableHeader"
        stripe
        show-summary
      >
        <el-table-column label="序号" align="center" type="index" />
        <el-table-column
          label="班级编码"
          align="left"
          show-overflow-tooltip
          prop="projectCode"
        />
        <el-table-column
          label="班级名称"
          align="left"
          show-overflow-tooltip
          prop="projectName"
          width="220px"
        />
        <el-table-column
          label="应收金额"
          align="right"
          show-overflow-tooltip
          prop="amountReceivable"
        />
        <el-table-column
          label="实收金额"
          align="right"
          prop="actualReceivedFee"
        >
          <template slot-scope="scope">
            <div style="display: flex; align-items: center">
              <el-input
                size="small"
                v-model="scope.row.actualReceivedFee"
                clearable
                @blur="actualReceivedFeeBlur(scope.$index)"
                placeholder="请输入"
              ></el-input>
              <i
                class="el-icon-warning"
                style="color: red; font-size: 1rem"
                v-if="scope.row.icon"
              ></i>
            </div>
          </template>
        </el-table-column>
        <Empty slot="empty" />
      </el-table>
      <div
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: flex-end"
      >
        <el-form :model="ruleForm" ref="ruleForm" :rules="rules">
          <el-form-item label="到账日期:" label-width="130px" prop="date">
            <el-date-picker
              v-model="ruleForm.date"
              size="medium"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div style="margin-left: 15px">
          <el-button
            type="primary"
            size="small"
            @click="dialogPartialCollectionSure('ruleForm', '10')"
            class="bgc-bv"
            >全部到账</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="dialogPartialCollectionSure('ruleForm', '20')"
            class="bgc-bv"
            >部分到账</el-button
          >
          <el-button @click="canclePartialCcollection('ruleForm')" size="small"
            >取 消</el-button
          >
        </div>
      </div>
    </el-dialog>
    <!-- 查看附件 -->
    <el-dialog title="查看附件" :visible.sync="lookfiledialog">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="fileListData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column label="序号" align="center" type="index" />
          <el-table-column
            label="姓名"
            align="center"
            show-overflow-tooltip
            prop="operatorName"
          />
          <el-table-column
            label="名称"
            align="center"
            show-overflow-tooltip
            prop="docType"
          >
            <template slot-scope="scope">
              {{
                scope.row.docType == "10"
                  ? "结算单照片"
                  : scope.row.docType == "20"
                  ? "结算单原单"
                  : "其他"
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="上传日期"
            align="center"
            show-overflow-tooltip
            min-width="180"
            prop="createTime"
          >
            <template slot-scope="scope">
              {{ scope.row.createTime | moment }}
            </template>
          </el-table-column>
          <el-table-column
            label="备注"
            align="center"
            show-overflow-tooltip
            prop="remark"
          />
          <el-table-column
            label="操作"
            align="center"
            show-overflow-tooltip
            prop="amountReceivable"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                :disabled="!scope.row.fileUrl"
                @click="lookupfileUrl(scope.row)"
                >查看</el-button
              >
            </template>
          </el-table-column>

          <Empty slot="empty" />
        </el-table>
      </div>
    </el-dialog>
    <!-- 查看附件 - 查看 -->
    <el-dialog
      title="预览"
      :visible.sync="fileUrldiaLog"
      width="50%"
      top="2%"
      @close="fileUrldiaLogClose"
      center
    >
      <div class="ovy-a">
        <div
          id="pdf-cert1"
          style="height: 600px"
          v-if="fileType == 'pdf' || fileType == 'PDF'"
        ></div>
        <div v-else>
          <img :src="ImgSrc" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
    <!-- 开票 -->
    <el-dialog
      title="开票"
      :visible.sync="bgkpVisible"
      width="80%"
      @close="bfkpQX('kpData')"
    >
      <el-table
        ref="kpData2"
        :data="kpData.bfkpData"
        size="small"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="tableHeader"
        stripe
        show-summary
      >
        <el-table-column label="序号" align="center" type="index" />
        <el-table-column
          label="班级编码"
          align="left"
          show-overflow-tooltip
          prop="projectCode"
        />
        <el-table-column
          label="班级名称"
          align="left"
          show-overflow-tooltip
          prop="projectName"
          width="220px"
        />
        <el-table-column
          label="应收金额"
          align="right"
          show-overflow-tooltip
          prop="amountReceivable"
        />
        <el-table-column
          label="实收金额"
          align="right"
          show-overflow-tooltip
          prop="actualReceivedFee"
        />
        <el-table-column label="开票金额" align="right" prop="invoiceFee">
          <template slot-scope="scope">
            <div style="display: flex; align-items: center">
              <el-input
                size="small"
                class="txtRight"
                v-model="scope.row.invoiceFee"
                @keyup.native="
                  scope.row.invoiceFee = zF.oninput2(scope.row.invoiceFee, 2)
                "
                clearable
                placeholder="请输入"
              ></el-input>
            </div>
          </template>
        </el-table-column>
        <Empty slot="empty" />
      </el-table>
      <div
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: flex-end"
      >
        <el-form :model="kpData" ref="kpData" :rules="rules" :inline="true">
          <el-form-item
            label="票据类型:"
            label-width="100px"
            prop="invoiceType"
          >
            <el-select
              size="small"
              v-model="kpData.invoiceType"
              placeholder="请选择票据种类"
              clearable
            >
              <el-option
                v-for="item in invoice_type"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开票日期:" label-width="100px" prop="date">
            <el-date-picker
              v-model="kpData.date"
              size="medium"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>

        <div style="margin-left: 15px">
          <el-button
            type="primary"
            size="small"
            @click="bfkpQd('kpData', 1)"
            class="bgc-bv"
            >全部开票</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="bfkpQd('kpData', 2)"
            class="bgc-bv"
            >部分开票</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="bfkpZf('kpData')"
            class="bgc-bv"
            >开票作废</el-button
          >
          <el-button @click="bfkpQX('kpData')" size="small">取 消</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 设置所属年度 - 弹框 -->
    <el-dialog
      title="设置所属年度"
      :visible.sync="setYearData.setYearDataLoading"
      width="400px"
      top="10%"
      @close="closeSetYearLoading"
      center
      ><div class="ovy-a">
        <el-form ref="setYearData" :model="setYearData" label-width="80px">
          <el-form-item label="选择年份">
            <el-select
              size="small"
              v-model="setYearData.affiliationYear"
              placeholder="请选择选择年份"
              clearable
              ><el-option
                v-for="item in setYearData.yearSelectData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer" style="float: right">
          <el-button
            type="primary"
            size="small"
            class="bgc-bv"
            @click="closeSetYearLoading"
            >取 消</el-button
          >
          <el-button
            type="primary"
            size="small"
            class="bgc-bv"
            @click="okSetYearLoading"
            >确 定</el-button
          >
        </span>
      </div>
    </el-dialog>
    <!-- 设置现负责人 - 弹框 -->
    <el-dialog
      title="设置现负责人"
      :visible.sync="setScenePersonInChargeData.setScenePersonInLoading"
      width="400px"
      top="10%"
      @close="closeScenePersonInLoading"
      center
      ><div class="ovy-a">
        <el-form
          ref="setScenePersonInChargeData"
          :model="setScenePersonInChargeData"
          label-width="100px"
        >
          <el-form-item label="现负责人">
            <el-select
              size="small"
              v-model="setScenePersonInChargeData.currentPrincipal"
              placeholder="请选择现负责人"
              clearable
              ><el-option
                v-for="item in sjcurrentPrincipaltData"
                :key="item.saleId"
                :label="item.saleName"
                :value="item.saleId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer" style="float: right">
          <el-button
            type="primary"
            size="small"
            class="bgc-bv"
            @click="closeScenePersonInLoading"
            >取 消</el-button
          >
          <el-button
            type="primary"
            size="small"
            class="bgc-bv"
            @click="okScenePersonInLoading"
            >确 定</el-button
          >
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import pdf from "pdfobject";
import { console } from "@/utils/helper";
export default {
  name: "evaluate/statementManagementFinance",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      billId: "", // 结算单id
      payerAmountSum: "",
      settlementUserCountSum: "",
      retrievalDatas: {
        auditType: "",
        compId: "",
        billNo: "",
        startTime: "",
        invoice: "",
        createTime: "",
        payerName: "",
        withImg: "",
        withBill: "",
        chargeUp: "",
        invoiceType: "",
        affiliationYear: "",
        currentPrincipal: "",
        replacePayerName:"" // 代付款单位
      },
      CompanyList: [],
      statusList: [], //结算单状态list
      ruleForm: {
        date: "",
      },
      rules: {
        invoiceType: [
          { required: true, message: "请选择票据类型", trigger: "change" },
        ],
        date: {
          required: true,
          message: "请选择日期",
          trigger: "change",
        },
      },
      dialogFormVisible: false,
      dialogTitle: "",
      labelTitle: "",
      placeholder: "",
      dialogCert: false,
      ListData: [],
      dialogPartialCcollectionVisible: false,
      ProjectData: [],
      lookfiledialog: false, //查看附件
      fileListData: [],
      fileUrldiaLog: false, //附件预览
      ImgSrc: "",
      fileType: "",
      kpData: {
        invoiceType: "", //票据类型
        date: "", // 开票日期
        bfkpData: [], // 部分开票列表数据
      },
      bgkpVisible: false,
      invoice_type: [], // 票据种类 - 下拉数据
      retrievalYearSelectData: [], // 检索 - 年份下拉数据
      currentPrincipaltData: [], // 检索 - 现负责人下拉数据
      sjcurrentPrincipaltData: [], // 设置 - 现负责人下拉数据
      multipleSelection: [], // 已选中的列表数据
      // 设置所属年度 - 数据
      setYearData: {
        setYearDataLoading: false, // 设置所属年度 - 弹框
        billId: [], // 结算单id集合
        yearSelectData: [], // 年份下拉数据
        affiliationYear: new Date().getFullYear(), // 所选年份
      },
      // 设置现负责人 - 数据
      setScenePersonInChargeData: {
        setScenePersonInLoading: false, // 设置现负责人 - 弹框
        billId: [], // 结算单id集合
        currentPrincipal: "", // 现负责人
      },
    };
  },
  created() {
    this.getInvoice_type();
    this.getScenePersonInChargeData();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  mounted() {
    this.getTableHeight();
    this.getStatusList();
  },
  methods: {
    // 获取 - 获取字典
    getInvoice_type() {
      let arr = this.$setDictionary("INVOICE_TYPE", "list");
      for (const key in arr) {
        this.invoice_type.push({
          label: arr[key],
          value: key,
        });
      }
      // 设置所属年度
      let arr1 = this.$setDictionary("BILL_AFFILIATION_YEAR", "list");
      for (const key in arr1) {
        if (arr1[key] == "999") {
          this.retrievalYearSelectData.push({
            label: "未设置",
            value: arr1[key],
          });
        } else {
          this.setYearData.yearSelectData.push({
            label: arr1[key],
            value: arr1[key],
          });
          this.retrievalYearSelectData.push({
            label: arr1[key],
            value: arr1[key],
          });
        }
      }
    },
    // 获取 - 现负责人下拉数据
    getScenePersonInChargeData() {
      this.$post("/biz/new/bill/contract/findSaleManList", {}).then((res) => {
        this.sjcurrentPrincipaltData = res.data;
        this.currentPrincipaltData = [...res.data, ... [{
          saleId: -1,
          saleName: "未设置"
        }]];
      });
    },
    // 下载班级列表
    listDownloadClassList(row) {
      this.$post("/biz/new/bill/downloadProjectDetail", { billId: row.billId })
        .then((ret) => {
          if (ret.status == "0") {
            let list = ret.data[0];
            if (!this.downloadItems.includes(list.taskId)) {
              this.$store.dispatch("pushDownloadItems", list.taskId);
            } else {
              this.$message.warning(
                "[" + list.fileName + "]已经申请下载,请耐心等待"
              );
            }
            this.closeDocsDialog();
          } else {
            this.$message.error(ret.message);
          }
          console.log(ret);
        })
        .catch((err) => {
          return;
        });
    },
    // 下载结算单
    listDownloadStatement(row) {
      // this.previewLoding = true;
      this.$post("/biz/new/bill/review", { billId: row.billId })
        .then((ret) => {
          this.$nextTick(() => {
            this.download(
              ret.message,
              row.payerName +
                "_" +
                row.billNo +
                "_" +
                this.getNowFormatDate() +
                ".pdf"
            );
          });
        })
        .catch((err) => {
          return;
        });
      // this.getoperation(billId);
    },
    download(url, filename) {
      this.getBlob(url, (blob) => {
        this.saveAs(blob, filename);
      });
    },
    getBlob(url, cb) {
      let xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = function () {
        if (xhr.status === 200) {
          cb(xhr.response);
        }
      };
      xhr.send();
    },
    saveAs(blob, filename) {
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        let link = document.createElement("a");
        let body = document.querySelector("body");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        // fix Firefox
        link.style.display = "none";
        body.appendChild(link);
        link.click();
        body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      }
    },
    // 获取当前日期
    getNowFormatDate() {
      let date = new Date(),
        seperator1 = "-", //格式分隔符
        year = date.getFullYear(), //获取完整的年份(4位)
        month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
        strDate = date.getDate(); // 获取当前日(1-31)
      if (month >= 1 && month <= 9) month = "0" + month; // 如果月份是个位数，在前面补0
      if (strDate >= 0 && strDate <= 9) strDate = "0" + strDate; // 如果日是个位数，在前面补0

      let currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    /* 修改记账 */
    handleCharge(chargeUp, billId, index) {
      this.$post("/biz/new/bill/isChargeUp", {
        billId,
        chargeUp,
      })
        .then((ret) => {
          if (ret.status == "0") {
            this.getData(-1);
          }
        })
        .catch((err) => {});
    },
    // 预览
    goWatch(billId) {
      this.dialogCert = true;
      this.$post("/biz/new/bill/review", { billId })
        .then((ret) => {
          this.$nextTick(() => {
            pdf.embed(ret.message, "#pdf-cert");
          });
        })
        .catch((err) => {
          return;
        });
      this.getoperation(billId);
    },
    getoperation(billId) {
      this.$post("/biz/new/audit/list", { billId })
        .then((ret) => {
          console.log(ret.data);
          this.ListData = ret.data || [];
        })
        .catch((err) => {
          return;
        });
    },
    //复核
    goAudit(billId, contractId, name) {
      //跳页
      this.$router.push({
        path: "/web/financialSettlement/statementManagementFinanceToReview",
        query: {
          billId,
          contractId,
          stu: "collection",
          name,
        },
      });
    },
    //确认收回结算单
    goConfirmBill(billId, stu) {
      this.billId = billId;
      this.stu = stu;
      this.dialogTitle = "收回结算单确认";
      this.labelTitle = "收回结算单日期";
      this.placeholder = "请选择收回结算单日期";
      this.dialogFormVisible = true;
    },
    //确认收款
    goconfirmMoney(billId, stu) {
      //弹框
      this.billId = billId;
      this.stu = stu;
      this.dialogTitle = "收款确认";
      this.labelTitle = "收款日期";
      this.placeholder = "请选择收款日期";
      this.dialogFormVisible = true;
    },
    //部分收款
    partialCollection(billId, stu) {
      this.billId = billId;
      this.dialogPartialCcollectionVisible = true;
      this.getProjectDetailList(billId);
    },
    getProjectDetailList(billId) {
      this.$post("/biz/new/bill/projectDetailList", { billId })
        .then((ret) => {
          ret.data.map((el) => {
            el.icon = false;
            if (el.actualReceivedFee > el.amountReceivable) {
              el.icon = true;
            }
          });
          this.ProjectData = ret.data || [];
        })
        .catch((err) => {
          return;
        });
    },
    dialogPartialCollectionSure(formName, stu) {
      let params = {
        billId: this.billId,
      };
      let projectAccountInfoList = [];
      for (let i = 0; i < this.ProjectData.length; i++) {
        projectAccountInfoList.push({
          actualReceivedFee: this.ProjectData[i].actualReceivedFee,
          billProjectId: this.ProjectData[i].billProjectId,
        });
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          params.payeeDate = this.ruleForm.date;
          params.projectAccountInfoList = projectAccountInfoList;

          if (stu == "10") {
            this.$confirm(
              "结算单全部到账后,状态不可逆,是否继续【全部到账】?",
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                confirmButtonClass: "bgc-bv",
                type: "warning",
                closeOnClickModal: false,
              }
            )
              .then(() => {
                this.$post("/biz/new/bill/allTheAccounts", params).then(
                  (res) => {
                    this.dialogPartialCcollectionVisible = false;
                    this.ruleForm.date = "";
                    this.getData(-1);
                  }
                );
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消全部到账",
                });
              });
          } else {
            this.$post("/biz/new/bill/partIntoAccount", params).then((ret) => {
              if (ret.status == "0") {
                this.dialogPartialCcollectionVisible = false;
                this.ruleForm.date = "";
                this.getData(-1);
              }
            });
          }
        }
      });
    },
    dialogFormVisibleSure(formName) {
      let params = {
        billId: this.billId,
      };
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.stu == "10") {
            params.billRecycleDate = this.ruleForm.date + " " + "00:00:00";
            this.$post("/biz/new/audit/confirmBill", params).then((ret) => {
              if (ret.status == "0") {
                this.$message({
                  message: "已回收结算单",
                  type: "success",
                });
                this.dialogFormVisible = false;
                this.ruleForm.date = "";
                this.getData(-1);
              }
            });
          } else {
            params.payeeDate = this.ruleForm.date + " " + "00:00:00";
            this.$post("/biz/new/audit/confirmArrive", params).then((ret) => {
              if (ret.status == "0") {
                this.$message({
                  message: "已确认收款",
                  type: "success",
                });
                this.dialogFormVisible = false;
                this.ruleForm.date = "";
                this.getData(-1);
              }
            });
          }
        }
      });
    },
    actualReceivedFeeBlur(index) {
      //  for(let i=0;i<this.ProjectData.length; i++) {
      if (
        this.ProjectData[index].actualReceivedFee >
        this.ProjectData[index].amountReceivable
      ) {
        this.ProjectData[index].icon = true;
      } else {
        this.ProjectData[index].icon = false;
      }
      // }
    },
    /* 弹框取消 */
    cancle(formName) {
      this.dialogFormVisible = false;
      this.ruleForm.date = "";
      this.$refs[formName].resetFields();
    },
    canclePartialCcollection(formName) {
      this.dialogPartialCcollectionVisible = false;
      this.ruleForm.date = "";
      this.$refs[formName].resetFields();
    },
    getStatusList() {
      const statusList = this.$setDictionary("FD_AUDIT_TYPE", "list");
      const list = [];
      for (const key in statusList) {
        list.push({
          value: key,
          label: statusList[key],
        });
      }
      let lists = list.concat([
        { value: "99", label: "已作废(直接生成)" },
        { value: "15", label: "未到账" },
      ]);

      this.statusList = lists;
    },
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        isInvoice: this.retrievalDatas.invoice,
        withImg: this.retrievalDatas.withImg,
        withBill: this.retrievalDatas.withBill,
        chargeUp: this.retrievalDatas.chargeUp,
      };
      if (this.retrievalDatas.billNo) {
        params.billNo = this.retrievalDatas.billNo;
      }
      if (this.retrievalDatas.compId) {
        params.compId = this.retrievalDatas.compId;
      }
      if (this.retrievalDatas.startTime) {
        params.payeeStartDate = this.retrievalDatas.startTime[0];
        params.payeeEndDate = this.retrievalDatas.startTime[1];
      }
      if (this.retrievalDatas.createTime) {
        params.createStartTime = this.retrievalDatas.createTime[0];
        params.createEndTime = this.retrievalDatas.createTime[1];
      }
      if (this.retrievalDatas.auditType) {
        params.auditType = this.retrievalDatas.auditType;
      }
      if (this.retrievalDatas.payerName) {
        params.payerName = this.retrievalDatas.payerName;
      }
      if (this.retrievalDatas.invoiceType) {
        params.invoiceType = this.retrievalDatas.invoiceType;
      }
      if (this.retrievalDatas.affiliationYear) {
        params.affiliationYear = this.retrievalDatas.affiliationYear;
      }
      if (this.retrievalDatas.currentPrincipal) {
        params.currentPrincipal = this.retrievalDatas.currentPrincipal;
      }
      if (this.retrievalDatas.replacePayerName) {
        params.replacePayerName = this.retrievalDatas.replacePayerName;
      }
      this.getCount(params);
      this.doFetch({
        url: "/biz/new/bill/pageList",
        params,
        pageNum,
      });
    },
    //统计
    getCount(params) {
      this.$post("/biz/new/bill/selectPageUpStatistic", params)
        .then((ret) => {
          this.payerAmountSum = ret.data.payer_amount_sum;
          this.settlementUserCountSum = ret.data.settlement_user_count_sum;
        })
        .catch((err) => {
          return;
        });
    },
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
        X;
      } else {
        this.CompanyList = [];
      }
    },
    /* 导出 */
    getExport() {
      const params = {
        isInvoice: this.retrievalDatas.invoice,
        withImg: this.retrievalDatas.withImg,
        withBill: this.retrievalDatas.withBill,
        chargeUp: this.retrievalDatas.chargeUp,
      };
      if (this.retrievalDatas.billNo) {
        params.billNo = this.retrievalDatas.billNo;
      }
      if (this.retrievalDatas.compId) {
        params.compId = this.retrievalDatas.compId;
      }
      if (this.retrievalDatas.startTime) {
        params.payeeStartDate = this.retrievalDatas.startTime[0];
        params.payeeEndDate = this.retrievalDatas.startTime[1];
      }
      if (this.retrievalDatas.createTime) {
        params.createStartTime = this.retrievalDatas.createTime[0];
        params.createEndTime = this.retrievalDatas.createTime[1];
      }
      if (this.retrievalDatas.auditType) {
        params.auditType = this.retrievalDatas.auditType;
      }
      if (this.retrievalDatas.payerName) {
        params.payerName = this.retrievalDatas.payerName;
      }
      if (this.retrievalDatas.affiliationYear) {
        params.affiliationYear = this.retrievalDatas.affiliationYear;
      }
      if (this.retrievalDatas.currentPrincipal) {
        params.currentPrincipal = this.retrievalDatas.currentPrincipal;
      }
      if (this.retrievalDatas.replacePayerName) {
        params.replacePayerName = this.retrievalDatas.replacePayerName;
      }
      this.$post("/biz/new/bill/listExport", params)
        .then((res) => {
          if (res.status == "0") {
            let list = [];
            list.push(res.data);
            for (let item of list) {
              if (!this.downloadItems.includes(item.taskId)) {
                this.$store.dispatch("pushDownloadItems", item.taskId);
              } else {
                this.$message.warning(
                  "[" + item.fileName + "]已经申请下载,请耐心等待"
                );
              }
            }
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 勾选列表多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 设置所属年度 - 弹框
    openYearLoading(row) {
      if (this.multipleSelection.length < 1 && !row?.billId) {
        this.$message.error("请勾选要设置的结算单!");
        return false;
      }
      if (row?.billId) {
        this.setYearData.billId = [row.billId];
        if(row.affiliationYear) {
          this.setYearData.affiliationYear = row.affiliationYear;
        }
      } else {
        this.setYearData.billId = this.multipleSelection.map((da) => {
          return da.billId;
        });
      }
      this.setYearData.setYearDataLoading = true;
    },
    // 设置所属年度 - 弹框 - 关闭
    closeSetYearLoading() {
      this.setYearData.affiliationYear = new Date().getFullYear();
      this.setYearData.setYearDataLoading = false;
    },
    // 设置所属年度 - 弹框 - 确定
    okSetYearLoading() {
      this.$post("/biz/new/bill/setAffiliationYear", {
        billIds: this.setYearData.billId,
        affiliationYear: Number(this.setYearData.affiliationYear),
      })
        .then((res) => {
          this.$refs.multipleTable.clearSelection();
          this.$message.success("设置成功！");
          this.closeSetYearLoading();
          this.getData(this.pageNum);
        })
        .catch((err) => {
          return;
        });
    },
    // 设置现负责人 - 弹框
    openScenePersonInChargeLoading(row) {
      if (this.multipleSelection.length < 1 && !row?.billId) {
        this.$message.error("请勾选要设置的结算单!");
        return false;
      }
      if (row?.billId) {
        this.setScenePersonInChargeData.billId = [row.billId];
        this.setScenePersonInChargeData.currentPrincipal = row.currentPrincipal == -1 ? '':row.currentPrincipal
      } else {
        this.setScenePersonInChargeData.billId = this.multipleSelection.map(
          (da) => {
            return da.billId;
          }
        );
      }
      this.setScenePersonInChargeData.setScenePersonInLoading = true;
    },
    // 设置现负责人 - 弹框 - 关闭
    closeScenePersonInLoading() {
      this.setScenePersonInChargeData.currentPrincipal = "";
      this.setScenePersonInChargeData.setScenePersonInLoading = false;
    },
    // 设置现负责人 - 弹框 - 确定
    okScenePersonInLoading() {
      this.$post("/biz/new/bill/setCurrentPrincipal", {
        billIds: this.setScenePersonInChargeData.billId,
        currentPrincipal: this.setScenePersonInChargeData.currentPrincipal,
      })
        .then((res) => {
          this.$refs.multipleTable.clearSelection();
          this.$message.success("设置成功！");
          this.closeScenePersonInLoading();
          this.getData(this.pageNum);
        })
        .catch((err) => {
          return;
        });
    },
    //批量记账
    doBatchChargeUp() {
      if (this.multipleSelection.length < 1) {
        this.$message.error("请勾选要记账的结算单!");
        return false;
      }
      let billIds = this.multipleSelection.map((da) => {
        return da.billId;
      });
      console.log(billIds)
      this.$post("/biz/new/bill/batch/chargeUp", {
        billIds: billIds
      })
          .then((res) => {
            this.$refs.multipleTable.clearSelection();
            this.$message.success("处理成功！");
            this.getData(this.pageNum);
          })
          .catch((err) => {
            return;
          });
    },
    //查看附件
    lookuploadfile(billId) {
      this.billId = billId;
      this.lookfiledialog = true;
      this.getFileList(billId);
    },
    getFileList(billId) {
      this.$post("/biz/new/bill/billFileList", { billId }).then((ret) => {
        if (ret.status == 0) {
          this.fileListData = ret.data.fdNewBillFileBOList || [];
        }
      });
    },
    lookupfileUrl(row) {
      this.fileUrldiaLog = true;
      this.fileType = row.fileType;
      if (row.fileType == "pdf" || row.fileType == "PDF") {
        this.$nextTick(() => {
          pdf.embed(row.fileUrl, "#pdf-cert1");
        });
      } else {
        this.ImgSrc = row.fileUrl;
      }
    },
    see(row) {
      this.fileUrldiaLog = true;
      this.fileType = row.fileType;
      this.$post("/biz/new/audit/auditFileView", { auditId: row.auditId })
        .then((ret) => {
          if (row.fileType == "pdf" || row.fileType == "PDF") {
            this.$nextTick(() => {
              pdf.embed(ret.message, "#pdf-cert1");
            });
          } else {
            this.ImgSrc = ret.message;
          }
        })
        .catch((err) => {
          return;
        });
      // this.getCourseList(billId)
      // this.getoperation(row.billId);
    },
    // 部分开票
    bkpList(billId, invoiceType, invoiceDate) {
      this.billId = billId;
      this.kpData.invoiceType = invoiceType || "";
      this.kpData.date = invoiceDate || "";
      //   ? invoiceDate.split(" ")[0].replaceAll("/", "-")
      //   : "";
      this.bgkpVisible = true;
      this.$post("/biz/new/bill/projectDetailList", { billId })
        .then((ret) => {
          ret.data.map((el) => {
            el.invoiceFee = el.invoiceFee ? el.invoiceFee : "";
          });
          this.kpData.bfkpData = ret.data || [];
        })
        .catch((err) => {
          return;
        });
    },
    // 部分开票 - 取消
    bfkpQX(formName) {
      this.bgkpVisible = false;
      this.kpData.date = "";
      this.kpData.bfkpData = [];
      this.$refs[formName].resetFields();
    },
    // 部分开票 - 确定
    bfkpQd(formName, type) {
      let projectAccountInfoList = [];
      for (let i = 0; i < this.kpData.bfkpData.length; i++) {
        projectAccountInfoList.push({
          invoiceFee: this.kpData.bfkpData[i].invoiceFee,
          billProjectId: this.kpData.bfkpData[i].billProjectId,
        });
      }
      let params = {
        billId: this.billId,
        isInvoice: type,
        invoiceType: this.kpData.invoiceType,
        invoiceDate: this.kpData.date,
        projectInvoiceInfoList: projectAccountInfoList,
      };
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post("/biz/new/bill/pay/modifyIsInvoicePart", params).then(
            (ret) => {
              if (ret.status == "0") {
                this.bfkpQX("kpData");
                this.getData(-1);
              }
            }
          );
        }
      });
    },
    // 部分开票 - 作废
    bfkpZf() {
      let params = {
        billId: this.billId,
      };
      this.$post("/biz/new/bill/pay/invoiceVoid", params).then((ret) => {
        if (ret.status == "0") {
          this.$message.success(ret.message);
          this.bfkpQX("kpData");
          this.getData(-1);
        }
      });
    },
    //回退
    backOff(billId) {
      this.$confirm("确认要回退到待客户确认状态吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
      })
        .then(() => {
          this.$post("/biz/new/bill/operationRollback", { billId }).then(
            (res) => {
              if (res.status == 0) {
                this.$message.success(res.message);
                this.getData(-1);
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3 + 6) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 80;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
        if (oldVal.query.stu == "add") {
          (this.searchData.billNo = ""),
            (this.searchData.compId = ""),
            (this.searchData.startTime = ""),
            (this.searchData.auditType = ""),
            (this.pageNum = 1);
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.el-div {
  display: block;
  height: 1px;
  width: 100%;
  margin: 24px 0;
  border-bottom: 1px solid #dcdfe6;
}
.searchbox > div .el-input {
  flex: 1;
}
.searchboxItem {
  display: flex;
  align-items: center;
  .itemLabel {
    min-width: 6rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
/deep/ .txtRight .el-input__inner{
  text-align: right;
}
</style>
