<template>
  <div>
    <div
      class="operationControl flexdc operationControlWidth"
      style="align-items: flex-start"
    >
      <div class="searchbox" style="margin-bottom: 5px">
        <div title="结算单编号" class="searchboxItem ci-full">
          <span class="itemLabel">结算单编号:</span>
          <el-input
            v-model="retrievalDatas.billNo"
            type="text"
            size="small"
            placeholder="请输入结算单编号"
            clearable
          />
        </div>
        <div title="代理商" class="searchboxItem ci-full">
          <span class="itemLabel" style="min-width: 6rem">代理商:</span>
          <el-select
            size="small"
            v-model="retrievalDatas.agencyId"
            remote
            :remote-method="getAgencyList"
            filterable
            clearable
            placeholder="请至少输入两个字搜索"
          >
            <el-option
              v-for="item in AgencyList"
              :key="item.agencyId"
              :label="item.agencyName"
              :value="item.agencyId"
            ></el-option>
          </el-select>
        </div>
        <div title="结算单状态" class="searchboxItem ci-full">
          <span class="itemLabel">结算单状态:</span>
          <el-select
            size="small"
            v-model="retrievalDatas.auditType"
            placeholder="请选择结算单状态"
            clearable
          >
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div title="是否开票" class="searchboxItem ci-full">
          <span class="itemLabel">是否开票:</span>
          <el-select
            size="small"
            v-model="retrievalDatas.invoice"
            placeholder="请选择是否开票"
            clearable
          >
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="0"></el-option>
          </el-select>
        </div>
      </div>
      <div class="searchbox" style="margin: 10px 0 5px">
        <div title="收款单位" class="searchboxItem ci-full">
          <span class="itemLabel">收款单位:</span>
          <el-input
            v-model="retrievalDatas.payeeName"
            type="text"
            size="small"
            placeholder="请输入收款单位"
            clearable
          />
        </div>
        <div title="付款日期" class="searchboxItem ci-full">
          <span class="itemLabel">付款日期:</span>
          <el-date-picker
            clearable
            size="small"
            v-model="retrievalDatas.startTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </div>
        <div title="创建日期" class="searchboxItem ci-full">
          <span class="itemLabel">创建日期:</span>
          <el-date-picker
            clearable
            size="small"
            v-model="retrievalDatas.createTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </div>
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          round
          @click="getData()"
          >查询</el-button
        >
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          round
          @click="handleExport()"
          >导出</el-button
        >
      </div>
    </div>
    <div style="margin: 15px 0.675rem 5px">
      <span>合计付款金额：{{ totalCourseMoney }} 元</span>
      <span style="margin: 0 30px">合计结算人数：{{ totalSettleNum }} 人</span>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          :row-class-name="tableRowClassName"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            fixed
            :index="indexMethod"
          />
          <el-table-column
            label="结算单编号"
            align="left"
            show-overflow-tooltip
            prop="billNo"
            min-width="180px"
            fixed
          />
          <el-table-column
            label="代理商"
            align="left"
            show-overflow-tooltip
            prop="compName"
            min-width="250px"
          />
          <el-table-column
            label="收款单位"
            align="left"
            show-overflow-tooltip
            prop="payeeName"
            min-width="200px"
          />
          <!-- <el-table-column
            label="付款金额"
            align="right"
            show-overflow-tooltip
            prop="payerAmount"
            max-width="200px"
            min-width="200px"
          /> -->
          <el-table-column
            label="应付服务费"
            align="left"
            show-overflow-tooltip
            prop="payerAmount"
          />
          <el-table-column
            label="实付服务费"
            align="left"
            show-overflow-tooltip
            prop="agencyActualFee"
          />
          <el-table-column
            label="结算人数"
            align="right"
            show-overflow-tooltip
            prop="settleNum"
            max-width="100px"
          />
          <el-table-column
            label="未领取补贴人数"
            align="right"
            show-overflow-tooltip
            prop="notSubsidiesNum"
            min-width="110px"
          >
          <template slot-scope="{ row }">
              {{row.notSubsidiesNum==0?'--':row.notSubsidiesNum}}
          </template>
          </el-table-column>
          <el-table-column
            label="付款日期"
            align="right"
            show-overflow-tooltip
            prop="payeeDate"
            max-width="150px"
            min-width="150px"
          >
            <template slot-scope="scope">
              {{ scope.row.payeeDate | momentDate }}
            </template>
          </el-table-column>
          <el-table-column label="是否开票" align="center" max-width="150">
            <div slot-scope="scope" class="table-switch">
              <el-switch
                :width="20"
                v-model="scope.row.isInvoice"
                :disabled="scope.row.auditType == '90'"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                @change="
                  (val) => {
                    handleStu(
                      scope.row.isInvoice,
                      scope.row.billId,
                      scope.$index
                    );
                  }
                "
              ></el-switch>
            </div>
          </el-table-column>

          <el-table-column
            label="结算单状态"
            align="center"
            show-overflow-tooltip
            prop="audit_type"
            max-width="300px"
            min-width="150px"
          >
            <template slot-scope="scope">
              <span>
                {{
                  $setDictionary("FD_AUDIT_TYPE", scope.row.auditType) ==
                  "已到账"
                    ? "已付款"
                    : $setDictionary("FD_AUDIT_TYPE", scope.row.auditType)
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="创建日期"
            align="right"
            show-overflow-tooltip
            prop="createTime"
            max-width="150px"
            min-width="150px"
          >
            <template slot-scope="scope">
              {{ scope.row.createTime | moment }}
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="220px"
            fixed="right"
          >
            <template slot-scope="scope">
              <div style="text-align: left">
                <el-button
                  type="text"
                  size="mini"
                  style="padding: 0 5px"
                  :disabled="scope.row.auditType == '10'"
                  @click="goWatch(scope.row.billId)"
                  >预览</el-button
                >

                <el-button
                  type="text"
                  size="mini"
                  style="padding: 0 5px"
                  :disabled="
                    scope.row.auditType != '20' &&
                    scope.row.auditType != '30' &&
                    scope.row.auditType != '40'
                  "
                  @click="goAudit(scope.row.billId,scope.row.contractId, 'four')"
                  >{{
                    scope.row.auditType == "30" || scope.row.auditType == "40"
                      ? "再次复核"
                      : "复核"
                  }}</el-button
                >

                <el-button
                  type="text"
                  size="mini"
                  style="padding: 0 5px"
                  :disabled="scope.row.auditType != '50'"
                  @click="goconfirmMoney(scope.row.billId, '20')"
                  >确认付款</el-button
                >
                <el-button
                  type="text"
                  size="mini"
                  style="padding: 0 5px"
                  @click="rollback(scope.row.billId)"
                  >回退</el-button
                >
                <el-button
                  type="text"
                  size="mini"
                  style="padding: 0 5px"
                  @click="lookuploadfile(scope.row.billId)"
                  >查看附件</el-button
                >
                <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="listDownloadClassList(scope.row)"
                >下载班级列表
                </el-button>
                <!-- <el-popover
                  placement="bottom"
                  trigger="click">
                  <p style="text-align: center;">下载结算单时是否带章</p>
                  <div style="text-align: center;">
                    <el-button type="text" size="mini" style="padding: 0 5px" @click="listDownloadStatement(scope.row,true)">带电子章</el-button> -->
                    <el-button type="text" size="mini" style="padding: 0 5px" @click="listDownloadStatement(scope.row,false)">下载结算单</el-button>
                  <!-- </div>
                  <el-button slot="reference" size="mini" type="text">下载结算单</el-button>
                </el-popover> -->
              </div>
            </template>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <!-- 收款确认 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogFormVisible"
      @close="cancle('ruleForm')"
    >
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules">
        <el-form-item :label="labelTitle + ':'" label-width="130px" prop="date">
          <el-date-picker
            v-model="ruleForm.date"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancle('ruleForm')">取 消</el-button>
        <el-button
          type="primary"
          @click="dialogFormVisibleSure('ruleForm')"
          class="bgc-bv"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="结算单"
      :visible.sync="dialogCert"
      width="50%"
      top="2%"
      center
      :before-close="closeDownloadCert"
    >
      <div style="height: 580px" class="ovy-a">
        <div id="pdf-cert" style="height: 580px"></div>
        <div class="el-div"></div>
        <h3 style="padding-bottom: 20px">流转记录</h3>
        <div>
          <el-table
            ref="multipleTable"
            :data="ListData"
            size="small"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="tableHeader"
            stripe
          >
            <el-table-column
              label="审核时间"
              align="center"
              show-overflow-tooltip
              prop="createTime"
            />
            <el-table-column
              label="审核人"
              align="center"
              show-overflow-tooltip
              prop="fullname"
            />
            <el-table-column
              label="审核状态"
              align="center"
              show-overflow-tooltip
              prop="auditType"
            >
              <template slot-scope="scope">
                <span>
                  {{
                    $setDictionary(
                      "FD_NEW_AUDIT_TYPE_RECORD",
                      scope.row.auditType
                    )
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              label="备注"
              align="center"
              show-overflow-tooltip
              prop="remark"
            >
              <template slot-scope="scope">
                {{ scope.row.remark || "--" }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
    <!-- 查看附件 -->
    <el-dialog title="查看附件" :visible.sync="lookfiledialog">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="fileListData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column label="序号" align="center" type="index" />
          <el-table-column
            label="姓名"
            align="center"
            show-overflow-tooltip
            prop="operatorName"
          />
          <el-table-column
            label="名称"
            align="center"
            show-overflow-tooltip
            prop="docType"
          >
            <template slot-scope="scope">
              {{
                scope.row.docType == "10"
                  ? "结算单照片"
                  : scope.row.docType == "20"
                  ? "结算单原单"
                  : "其他"
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="上传日期"
            align="center"
            show-overflow-tooltip
            min-width="180"
            prop="createTime"
          >
            <template slot-scope="scope">
              {{ scope.row.createTime | moment }}
            </template>
          </el-table-column>
          <el-table-column
            label="备注"
            align="center"
            show-overflow-tooltip
            prop="remark"
          />
          <el-table-column
            label="操作"
            align="center"
            show-overflow-tooltip
            prop="amountReceivable"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                :disabled="!scope.row.fileUrl"
                @click="lookupfileUrl(scope.row)"
                >查看</el-button
              >
            </template>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </el-dialog>
    <el-dialog
      title="预览"
      :visible.sync="fileUrldiaLog"
      width="50%"
      top="2%"
      @close="fileUrldiaLogClose"
      center
    >
      <div class="ovy-a">
        <div
          id="pdf-cert1"
          style="height: 600px"
          v-if="fileType == 'pdf' || fileType == 'PDF'"
        ></div>
        <div v-else>
          <img :src="ImgSrc" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
export default {
  name: "evaluate/statementManagementFinance",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      payerAmountSum: "",
      settlementUserCountSum: "",
      retrievalDatas: {
        auditType: "",
        agencyId: "",
        billNo: "",
        payeeName:"",
        startTime: "",
        invoice: "",
        createTime: "",
      },
      CompanyList: [],
      AgencyList: [], //代理商名称
      statusList: [], //结算单状态list
      ruleForm: {
        date: "",
      },
      rules: {
        date: {
          required: true,
          message: "请选择日期",
          trigger: "change",
        },
      },
      dialogFormVisible: false,
      dialogTitle: "",
      labelTitle: "",
      placeholder: "",
      dialogCert: false,
      ListData: [],
      lookfiledialog: false, //查看附件
      fileListData: [],
      fileUrldiaLog: false, //附件预览
      ImgSrc: "",
      fileType: "",
    };
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  created() {},
  mounted() {
    this.getTableHeight();
    this.getStatusList();
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
        if (row.auditType == '90') {
          return 'cred';
        }
        return '';
    },
     //回退
    rollback(billId){
      this.$post("/biz/new/billAgent/finance/operationRollback", { billId })
      .then((res) => {
        this.$message.success('回退成功！')
        this.getData(-1)
      })
      .catch((err) => {
        return;
      });
    },
    // 下载班级列表
    listDownloadClassList(row){
      this.$post("/biz/new/billAgent/finance/downloadProjectDetail", { billId: row.billId })
          .then((ret) => {
            if (ret.status == "0") {
              console.log(ret.data)
              let list = ret.data[0];
              if (!this.downloadItems.includes(list.taskId)) {
                this.$store.dispatch("pushDownloadItems", list.taskId);
              } else {
                this.$message.warning(
                    "[" + list.fileName + "]已经申请下载,请耐心等待"
                );
              }
              this.closeDocsDialog();
            } else {
              this.$message.error(ret.message);
            }
            console.log(ret)
          })
          .catch((err) => {
            return;
          });
    },

    // 下载结算单
    listDownloadStatement(row,isCa) {
      // this.previewLoding = true;
      this.$post("/biz/new/billAgent/finance/review", { billId: row.billId,isCa })
          .then((ret) => {
            this.$nextTick(() => {
              this.download(ret.message,row.compName+'_'+row.billNo+'_'+this.getNowFormatDate()+'.pdf')
            });
          })
          .catch((err) => {
            return;
          });
      // this.getoperation(billId);
    },
    download(url, filename) {
      this.getBlob(url,  (blob)=> {
        this.saveAs(blob, filename);
      });
    },
    getBlob(url, cb) {
      let xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      xhr.onload = function () {
        if (xhr.status === 200) {
          cb(xhr.response);
        }
      };
      xhr.send();
    },
    saveAs(blob, filename) {
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        let link = document.createElement('a');
        let body = document.querySelector('body');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        // fix Firefox
        link.style.display = 'none';
        body.appendChild(link);
        link.click();
        body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      };

    },
    // 获取当前日期
    getNowFormatDate() {
      let date = new Date(),
          seperator1 = '-', //格式分隔符
          year = date.getFullYear(), //获取完整的年份(4位)
          month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
          strDate = date.getDate() // 获取当前日(1-31)
      if (month >= 1 && month <= 9) month = '0' + month // 如果月份是个位数，在前面补0
      if (strDate >= 0 && strDate <= 9) strDate = '0' + strDate // 如果日是个位数，在前面补0

      let currentdate = year + seperator1 + month + seperator1 + strDate
      return currentdate
    },
    // 获取 - 机构名称
    getAgencyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/biz/mercantile/agency/searchList", {
          agencyName: query,
          agencyCooperationType: 20
        })
          .then((res) => {
            if (res.status == 0) {
              this.AgencyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.AgencyList = [];
      }
    },
    /* 修改是否开票 */
    handleStu(isInvoice, billId, index) {
      this.$post("/biz/new/bill/pay/modifyIsInvoice", {
        billId,
        isInvoice,
      })
        .then((ret) => {
          if (ret.status == "0") {
            this.getData(-1);
          }
        })
        .catch((err) => {
          if (err.data.status == "-1") {
            this.tableData[index].isInvoice = isInvoice;
          }
        });
    },
    // 预览
    goWatch(billId) {
      this.dialogCert = true;
      this.$post("/biz/new/billAgent/finance/review", { billId,isCa:false })
        .then((ret) => {
          this.$nextTick(() => {
            pdf.embed(ret.message, "#pdf-cert");
          });
        })
        .catch((err) => {
          return;
        });
      this.getoperation(billId);
    },
    getoperation(billId) {
      this.$post("/biz/new/audit/list", { billId })
        .then((ret) => {
          console.log(ret.data);
          this.ListData = ret.data || [];
        })
        .catch((err) => {
          return;
        });
    },
    //复核
    goAudit(billId, contractId,name) {
      //跳页
      this.$router.push({
        path: "/web/financialSettlement/statementManagementFinanceToReview",
        query: {
          billId,
          contractId,
          stu: "agent",
          name,
        },
      });
    },
    //确认付款
    goconfirmMoney(billId, stu) {
      //弹框
      this.billId = billId;
      this.stu = stu;
      this.dialogTitle = "付款确认";
      this.labelTitle = "付款日期";
      this.placeholder = "请选择付款日期";
      this.dialogFormVisible = true;
    },
    dialogFormVisibleSure(formName) {
      let params = {
        billId: this.billId,
      };

      this.$refs[formName].validate((valid) => {
        if (valid) {
          params.payeeDate = this.ruleForm.date + " " + "00:00:00";
          this.$post("/biz/new/billAgent/finance/confirmArrive", params).then(
            (ret) => {
              if (ret.status == "0") {
                this.$message({
                  message: "已确认付款",
                  type: "success",
                });
                this.dialogFormVisible = false;
                this.ruleForm.date = "";
                this.getData(-1);
              }
            }
          );
        }
      });
    },
    /* 弹框取消 */
    cancle(formName) {
      this.dialogFormVisible = false;
      this.ruleForm.date = "";
      this.$refs[formName].resetFields();
    },
    // 获取结算单状态
    getStatusList() {
      const statusList = this.$setDictionary("FD_AUDIT_TYPE", "list");
      const list = [];
      for (const key in statusList) {
        list.push({
          value: key,
          label: statusList[key],
        });
      }
      for (let i = 0; i < list.length; i++) {
        if (list[i].value == "70") {
          list[i].label = "已付款";
        }
      }
      for (let i = 0; i < list.length; i++) {
        if (list[i].value == "60") {
          list.splice(i, 1);
        }
      }
      this.statusList = list;
    },
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        isInvoice: this.retrievalDatas.invoice,
      };
      if (this.retrievalDatas.billNo) {
        params.billNo = this.retrievalDatas.billNo;
      }
      if (this.retrievalDatas.agencyId) {
        params.agencyId = this.retrievalDatas.agencyId;
      }
      if (this.retrievalDatas.startTime) {
        params.payeeDateStart = this.retrievalDatas.startTime[0];
        params.payeeDateEnd = this.retrievalDatas.startTime[1];
      }
      if (this.retrievalDatas.createTime) {
        params.createTimeStart = this.retrievalDatas.createTime[0];
        params.createTimeEnd = this.retrievalDatas.createTime[1];
      }
      if (this.retrievalDatas.auditType) {
        params.auditType = this.retrievalDatas.auditType;
      }
      if (this.retrievalDatas.payeeName) {
        params.payeeName = this.retrievalDatas.payeeName;
      }
      this.getCount(params);
      this.doFetch({
        url: "/biz/new/billAgent/finance/pageList",
        params,
        pageNum,
      });
    },
    //统计
    getCount(params) {
      this.$post("/biz/new/billAgent/finance/paymentStatementCount", params)
        .then((ret) => {
          this.totalCourseMoney = ret.data.totalCourseMoney;
          this.totalSettleNum = ret.data.totalSettleNum;
        })
        .catch((err) => {
          return;
        });
    },
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 80;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    },
    /* 导出 */
    handleExport() {
      let _this = this;
      _this
        .$confirm(
          "导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。",
          "导出提示",
          {
            confirmButtonText: "导出",
            confirmButtonClass: "confirmButtonClass",
            cancelButtonText: "取消",
          }
        )
        .then(() => {
          const params = {
            isInvoice: _this.retrievalDatas.invoice,
          };
          if (_this.retrievalDatas.billNo) {
            params.billNo = _this.retrievalDatas.billNo;
          }

          if (_this.retrievalDatas.agencyId) {
            params.agencyId = _this.retrievalDatas.agencyId;
          }
          if (_this.retrievalDatas.startTime) {
            params.payeeDateStart = _this.retrievalDatas.startTime[0];
            params.payeeDateEnd = _this.retrievalDatas.startTime[1];
          }
          if (_this.retrievalDatas.createTime) {
            params.createTimeStart = _this.retrievalDatas.createTime[0];
            params.createTimeEnd = _this.retrievalDatas.createTime[1];
          }
          if (_this.retrievalDatas.auditType) {
            params.auditType = _this.retrievalDatas.auditType;
          }
          if (_this.retrievalDatas.payeeName) {
            params.payeeName = _this.retrievalDatas.payeeName;
          }
          _this
            .$post("/biz/new/billAgent/finance/page/export", params)
            .then((res) => {
              if (res.status == "0") {
                let list = res.data;
                if (!_this.downloadItems.includes(list.taskId)) {
                  _this.$store.dispatch("pushDownloadItems", list.taskId);
                } else {
                  _this.$message.warning(
                    "[" + list.fileName + "]已经申请下载,请耐心等待"
                  );
                }
              } else {
                _this.$message.error(res.message);
              }
            });
        })
        .catch(() => {});
    },
    //查看附件
    lookuploadfile(billId) {
      this.billId = billId;
      this.lookfiledialog = true;
      this.getFileList(billId);
    },
    getFileList(billId) {
      this.$post("/biz/new/bill/billFileList", { billId }).then((ret) => {
        if (ret.status == 0) {
          this.fileListData = ret.data.fdNewBillFileBOList || [];
        }
      });
    },
    lookupfileUrl(row) {
      this.fileUrldiaLog = true;
      this.fileType = row.fileType;
      if (row.fileType == "pdf" || row.fileType == "PDF") {
        this.$nextTick(() => {
          pdf.embed(row.fileUrl, "#pdf-cert1");
        });
      } else {
        this.ImgSrc = row.fileUrl;
      }
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
        if (oldVal.query.stu == "add") {
          (this.searchData.billNo = ""),
            (this.searchData.compId = ""),
            (this.searchData.startTime = ""),
            (this.searchData.auditType = ""),
            (this.pageNum = 1);
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.el-div {
  display: block;
  height: 1px;
  width: 100%;
  margin: 24px 0;
  border-bottom: 1px solid #dcdfe6;
}
.searchbox > div .el-input {
  flex: 1;
}
.searchboxItem {
  display: flex;
  align-items: center;
  .itemLabel {
    min-width: 6rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
.el-table /deep/ .cred td{
  color: red!important;;
}
</style>
